import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { User } from '@capturum/complete';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { AppRoutes } from '@core/enums/routes.enum';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PrintService } from '@shared/services/print.service';
import { LocalStorageService } from '@shared/services/storage.service';
import { NotificationService } from '@shared/services/notification.service';
import { MenuItem } from '@core/interfaces/menu-item.interface';
import { LeadApiService } from '../../../features/lead/services/lead-api.service';
import { NotificationBadgeOptions } from '@capturum/ui/api';

type WithAsyncNotificationOptions<T extends MenuItem> = T & {
  notificationBadgeOptions?: Observable<NotificationBadgeOptions>;
};

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit, OnDestroy {
  public menuItems: WithAsyncNotificationOptions<MenuItem>[];
  public user: User;

  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private ngxPermissionService: NgxPermissionsService,
    private readonly printService: PrintService,
    private storageService: LocalStorageService,
    private notificationService: NotificationService,
    private leadService: LeadApiService
  ) {}

  public ngOnInit(): void {
    this.user = this.authService.getUser();
    this.menuItems = this.getMenuItems();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public executeCommand(item: MenuItem): void {
    if (item.command) {
      item.command(null);
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }

  private getMenuItems(): WithAsyncNotificationOptions<MenuItem>[] {
    return [
      {
        label: 'link.profile',
        icon$: 'fa-light fa-user',
        routerLink: `/${AppRoutes.profile}`,
        visible: true,
      },
      {
        label: 'config.manage.title',
        icon$: 'fa-light fa-cog',
        routerLink: `/${AppRoutes.config}`,
        visible: from(
          this.ngxPermissionService.hasPermission([
            'tenant.manage',
            'translation.manage.tenant',
            'role.manage.tenant',
            'meta.manage',
            'mutation.manage',
          ])
        ),
        notificationBadgeOptions: this.getManageConfigNotificationOptions(),
      },
      {
        label: null,
        icon$: this.printService.getPrintBusy().pipe(
          map((isBusy) => {
            return isBusy ? 'fa-duotone fa-spin fa-loader' : 'fa-light fa-print';
          })
        ),
        visible: this.printService.printButtonVisible$,
        disabled: this.printService.getPrintBusy(),
        command: () => {
          this.printService.printToPdf();
        },
      },
      {
        label: 'link.logout',
        icon$: 'fa-light fa-arrow-right-from-bracket',
        visible: true,
        command: () => {
          const rememberMe = this.storageService.get(LocalStorageKey.rememberMe);
          const isAgentQuestionnaire = this.router.url.includes('agent-questionnaire');

          if (isAgentQuestionnaire) {
            this.leadService.dequeueIsDone$.next(true);
          }

          const method = isAgentQuestionnaire ? this.leadService.dequeue() : of(null);

          method
            .pipe(
              switchMap(() => {
                return this.authService.logout();
              }),
              finalize(() => {
                if (rememberMe) {
                  this.storageService.set(LocalStorageKey.rememberMe, rememberMe);
                }
                this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
              }),
              take(1)
            )
            .subscribe();
        },
      },
    ];
  }

  private getManageConfigNotificationOptions(): Observable<NotificationBadgeOptions> {
    const defaults: NotificationBadgeOptions = {
      badgeSize: '1.2rem',
      backgroundColor: '#fa4331',
      fontColor: '#fff',
      fontSize: '0.6rem',
      showBorder: false,
    };

    if (!this.ngxPermissionService.getPermission('mutation.manage')) {
      return of(defaults);
    }

    return this.notificationService.getOpenMutationsCount().pipe(
      map((value) => {
        return {
          ...defaults,
          label: value > 99 ? '99+' : value.toString(),
          showBadge: value > 0,
        };
      }),
      takeUntil(this.destroy$)
    );
  }
}
